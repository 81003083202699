import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSlateStatic } from 'slate-react';
import { usePeople } from './PeopleContext';
import { PeopleWrapper, PersonTile } from '../../Committee/CommitteePeopleSelection';
import { Editor } from 'slate';
import { Typography } from '@mui/material';

export const PeopleBlockElementType = "people-block";

const Placeholder = styled.div`
    background: ${props => props.theme.palette.grey[100]};
    padding: 1rem 0.5rem;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
`;

export interface WithPeople {
    people?: number[];
}

interface OpenEdit {
    openEdit: (editor: Editor, element: WithPeople) => void;
}

export const PeopleBlockElement = (props: RenderElementProps & OpenEdit) => {
    const peopleData = usePeople();
    const people = (props.element as WithPeople).people || [];

    useEffect(() => {
        if(people.length > 0) {
            peopleData.ensureLoaded();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editor = useSlateStatic();

    const openEdit = () => props.openEdit(editor, props.element as WithPeople);

    return (
        <div>
            {props.children}
            <PeopleWrapper
                 style={{ width: "100%" }} contentEditable={false}
                onClick={openEdit}>
                {people.map(p => peopleData.peopleDict[p]).filter(p => !!p).map(p => (
                    <PersonTile
                        key={p._id}
                        person={p}
                        />
                ))}
            </PeopleWrapper>
            {people.length === 0 && <Placeholder onClick={openEdit}><Typography>Click to add people</Typography></Placeholder>}
        </div>
    );
}
