import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";

export interface Event {
    _id: string;
    title: string; 
    slug: string;
    lang: string; 
    event_datetime: string; 
    display_datetime?: string; 

    variant: string;
    cover_url?: string | null;
    video_url?: string; 
    pdf_url?: string | null; 
    content?: any;
    location_type?: string; 
    location?: string; 

    localization_id: string; 
    translations: any[];
    published_by?: string; 
    published_datetime?: string; 
}

export interface CreateEvent extends Pick<Event, "title" | "slug" | "lang"> {

}

export const EventsApiPath = "/api/event";

export const useEventsList = () => {
    const data = useCrudUnpagedList<Event>(EventsApiPath, {
        defaultView: "all",
        filterBy: n => `${n.title} ${n.slug} ${n._id}`,
    });

    const { schema } = useSingleSchema(`${EventsApiPath}/uiconfig`);

    const newItem = useNewItem<CreateEvent, Event>(EventsApiPath, {
        title: "",
        slug: "",
        lang: "ru",
    });

    return {
        ...data,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as Event);
        },
        newItem,
        schema,
    }
}
