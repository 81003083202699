import { useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { CustomElement } from "../../../slate";
import { generateCode } from "../../../toolympus/components/PowerDoc/plugins/common";

export interface Question {
    id: string;
    title?: string | null;
    content?: any;
}

export interface WithQuestions {
    questions: Question[];
}


export const useQnA = (element: Partial<WithQuestions> & CustomElement) => {
    const editor = useSlateStatic();
    const [localState, setLocalState] = useState<WithQuestions>({
        questions: element.questions || [],
    });
    const [insertedId, setInsertedId] = useState<string>("");

    const path = ReactEditor.findPath(editor, element);

    const updateState = (changes: Partial<WithQuestions>) => {
        const updated = { ...localState, ...changes };
        setLocalState(updated);
        Transforms.setNodes(
            editor,
            updated as any,
            { at: path },
        );
    }

    const insertQuestion = () => {
        const id = generateCode();
        updateState({
            questions: [ ...localState.questions, { id }],
        });
        setInsertedId(id);
    };

    const updateQuestion = (id: string, changes: Partial<Question>) => {
        updateState({
            questions: localState.questions.map(t => t.id === id ? { ...t, ...changes } : t),
        });
    }

    const removeQuestion = (id: string) => {
        updateState({
            questions: localState.questions.filter(t => t.id !== id),
        });
    }

    const moveQuestion = (id: string, direction: 1 | -1) => {
        const movedIdx = localState.questions.findIndex(t => t.id === id);
        if(movedIdx < 0) {
            return;
        }

        const resultIdx = movedIdx + direction;
        const questions = localState.questions;
        const moved = questions[movedIdx];
        if(resultIdx < 0) {
            updateState({
                questions: [...questions.slice(1), moved],
            });
        } else if (resultIdx >= questions.length) {
            updateState({
                questions: [moved, ...questions.slice(0, movedIdx)],
            });
        } else {
            const l = Math.min(movedIdx, resultIdx);
            updateState({
                questions: [...questions.slice(0,l), questions[l+1], questions[l], ...questions.slice(l+2),],
            });
        }
    }

    return {
        questions: localState.questions,
        insertQuestion: insertQuestion,
        updateQuestion,
        removeQuestion,
        moveQuestionUp: (id: string) => moveQuestion(id, -1),
        moveQuestionDown: (id: string) => moveQuestion(id, +1),
        insertedId,        
    }
}
