import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";

export interface News {
    _id: string;
    slug: string;
    title: string;
    lang: string;
    content: any;
    localization_id: string;
    translations: any[];
    news_datetime: string;
    display_datetime?: string;
    published_datetime?: string;
    published_by?: string;
}

export interface CreateNews extends Pick<News, "title" | "slug" | "lang"> {

}

export const NewsApiPath = "/api/news";

export const useNewsList = () => {
    const data = useCrudUnpagedList<News>(NewsApiPath, {
        defaultView: "all",
        filterBy: n => `${n.title} ${n.slug} ${n._id}`,
    });

    const { schema } = useSingleSchema(`${NewsApiPath}/uiconfig`);

    const newItem = useNewItem<CreateNews, News>(NewsApiPath, {
        title: "",
        slug: "",
        lang: "ru",
    });

    return {
        ...data,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as News);
        },
        newItem,
        schema,
    }
}
