import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { Form, FormGrid, OccupyFreeSpace, SaveButton, SlugEditControl } from '../../toolympus/components/primitives';
import { TranslationRecords } from '../Common/TranslationRecords';
import { YimaEditor } from '../YimaEditor';
import { usePageEdit } from './usePageEdit';
import { GoToSite } from '../Common/GoToSite';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { usePublishOrPublished } from '../Common/PublishOrPublishedDate';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';


export const PagePage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const data = usePageEdit(id);
    const { controls } = useItemWithControls(data, { schema: data.schema });
    useSaveable(data);

    const PublishedControl = usePublishOrPublished(v => data.save({ published_datetime: v }), [data.data.published_datetime]);

    const remove = useActionWithConfirmation(() => data.remove(), { title: "Delete page", confirmationLabel: "Delete" });

    return (
        <Form
            title="Page"
            headerItems={<>
                <OccupyFreeSpace />
                <GoToSite entity="page" id={data.data.slug} lang={data.data.lang} />
                <IconButton size="small" onClick={() => data.copyItem().then(n => history.push(`/page/${n._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                <IconButton size="small" color="secondary" onClick={() => remove.run()}>
                    <DeleteOutlined />
                </IconButton>
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            <FormGrid columns="2fr 1fr" alignItems="flex-end">
                {controls([
                  ["title"],
                  ["published_datetime", { utcToLocal: true, control: PublishedControl }],
                  ])}
            </FormGrid>

            <FormGrid style={{ alignItems: "end" }}>
                {controls([
                    ["slug", { control: SlugEditControl, slugSource: data.data?.title || "" }],
                    ["lang"],
                    ])}
                <TranslationRecords {...data.translations} getItemLink={t => `/page/${t._id}`} />
                    
            </FormGrid>

            <Typography variant="caption" color="textSecondary" style={{ marginTop: "1rem" }}>Content</Typography>
            <YimaEditor
                key={data.data._id}
                content={data.data.content}
                update={v => data.update({ content: v })}
                />
        </Form>
    );
}
