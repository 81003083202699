import { IconButton } from '@mui/material';
import { GetAppOutlined } from '@mui/icons-material';
import React from 'react';
import { Form, OccupyFreeSpace, LoadingIndicator, SearchField } from '../../toolympus/components/primitives';
import { TableForFields } from '../../toolympus/components/schemed';
import { useSubscribers } from './useSubscribers';

export const SubscribersList = () => {
    const data = useSubscribers();
    return (
        <Form title="Subscribers"
            headerItems={<>
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                <SearchField
                    filter={data.filter}
                    setFilter={data.setFilter}
                    noButton
                    autoFocus
                />
                <IconButton size="small" onClick={() => data.exportSubscribers()}>
                    <GetAppOutlined />
                </IconButton>
                </>}
            formHeaderProps={{ alignItems: "end" }}>

            <TableForFields
                fields={[
                    ["email"],
                    ["code"],
                    ["created_datetime", { utcToLocal: true }],
                ]}
                data={data.data}
                schema={data.schema}
                />
            
        </Form>
    );
}
