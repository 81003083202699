import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";

export interface Project {
    _id: string;
    title: string;
    slug?: string;
    description_brief?: string;
    description?: any;
    committee?: string | null;
    photo_url?: string | null;
    published_datetime?: string | null;
    translations?: any;
}

export interface CreateProject extends Pick<Project, "title"> {

}

export const ProjectApiPath = "/api/project";

export const useProjectList = () => {
    const data = useCrudUnpagedList<Project>(ProjectApiPath, {
        defaultView: "all",
        filterBy: n => `${n.title} ${n._id}`,
    });

    const { schema } = useSingleSchema(`${ProjectApiPath}/uiconfig`);

    const newItem = useNewItem<CreateProject, Project>(ProjectApiPath, {
        title: "",
    });

    return {
        ...data,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as Project);
        },
        newItem,
        schema,
    }
}
