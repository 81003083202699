import React from 'react';
import { useHistory } from 'react-router-dom';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { SimpleDialog, FormGrid, SimpleList, SlugEditControl } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CreateNews, News, useNewsList } from './useNewsList';

export const NewsList = () => {
    const data = useNewsList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="News"
            data={data}
            schema={data.schema}
            getLinkValue={n => `/news/${n._id}`}
            fields={[
                ["title"],
                ["slug"],
                ["lang"],
                ["news_datetime", { utcToLocal: true }],
                ["published_datetime", { utcToLocal: true }],
            ]}
            linkFields={["title", "slug"]}
            />
        <CreateNewsPopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`/news/${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreateNewsProps extends NewItemProps<CreateNews, News> {
    schema: Schema;
}

const CreateNewsPopup = (props: CreateNewsProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreateNews, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle="Make news"
            save={() => save()}
            noFullscreen
            submitOnModEnter
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title", { autoRows: true }],
                    ["slug", { control: SlugEditControl, slugSource: item?.title || "" }],
                    ["lang", { autoComplete: true }],
                ])}
            </FormGrid>
        </SimpleDialog>);
}