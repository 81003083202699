import { apiFetch } from "../../toolympus/api/core";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Translation, TranslationRecordsData } from "./TranslationRecords";

interface Config<T> {
    reload: () => void;
    apiPath: string;
    view?: string;
    transform?: (item: T) => Translation;
}

interface Item extends Translation {
    translations: Translation[];
}

export const useTranslationRecords = <T extends Item>(currentItem: T, cfg: Config<T>): TranslationRecordsData => {
    const data = useLoadedData<T[]>(`${cfg.apiPath}${cfg.view ? `?view=${cfg.view}` : ""}`, [], false);

    const available = data.data
        .filter(n => currentItem.lang !== n.lang && currentItem._id !== n._id && !currentItem.translations.find(t => t._id === n._id))
        .map(t => cfg.transform ? cfg.transform(t) : t);


    const addTranslation = (t: Translation) => {
        return apiFetch<Item>(
            `${cfg.apiPath}/${currentItem._id}/translation`,
            "put",
            { _id: t._id, lang: t.lang }
        )
        .then(() => {
            cfg.reload();
        });
    }

    const unlinkTranslation = (t: Translation) => {
        return apiFetch<Item>(
            `${cfg.apiPath}/${currentItem._id}/translation/${t._id}`,
            "delete"
        )
        .then(() => {
            cfg.reload();
        });
    }

    return {
        available,
        isLoading: data.isLoading,
        translations: currentItem.translations,
        loadAvailable: () => {
            data.reload();
        },
        addTranslation,
        unlinkTranslation,
    }
}
