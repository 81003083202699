import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { Person, PersonApiPath } from '../usePersonList';

interface IPeopleContext {
    people: Person[];
    peopleDict: Record<number, Person>;
    isLoading: boolean;
    ensureLoaded: () => void,
}

const PeopleContext = React.createContext<IPeopleContext>({
    people: [],
    peopleDict: {},
    isLoading: true,
    ensureLoaded: () => {},
});

export const usePeople = () => useContext(PeopleContext);

export const PeopleProvider = (props: PropsWithChildren<{}>) => {
    const [doLoad, setDoLoad] = useState<boolean>(false);
    const people = useLoadedData<Person[]>(PersonApiPath, [], doLoad);

    const peopleDict = useMemo(
        () => people.data.reduce<Record<number, Person>>((r,p) => { r[p._id] = p; return r; }, {}),
        [people.data]);

    return (
        <PeopleContext.Provider value={{
            people: people.data,
            isLoading: people.isLoading,
            ensureLoaded: () => setDoLoad(true),
            peopleDict,
        }}>
            {props.children}
        </PeopleContext.Provider>
    );
}
