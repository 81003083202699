import React from 'react';
import { useHistory } from 'react-router-dom';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { SimpleDialog, FormGrid, SimpleList, SlugEditControl } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CreateCommittee, Committee, useCommitteeList } from './useCommitteeList';

export const CommitteeList = () => {
    const data = useCommitteeList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="Committee"
            data={data}
            schema={data.schema}
            getLinkValue={n => `/committee/${n._id}`}
            fields={[
                ["title"],
                ["slug"],
                ["sortorder"],
            ]}
            linkFields={["title", "slug"]}
            />
        <CreateCommitteePopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`/committee/${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreateCommitteeProps extends NewItemProps<CreateCommittee, Committee> {
    schema: Schema;
}

const CreateCommitteePopup = (props: CreateCommitteeProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreateCommittee, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle="Create committee"
            save={() => save()}
            noFullscreen
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title"],
                    ["slug", { control: SlugEditControl, slugSource: item?.title || "" }],
                ])}
            </FormGrid>
        </SimpleDialog>);
}