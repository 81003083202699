import React from 'react';
import { LiveHelpOutlined } from "@mui/icons-material";
import { createWithVoidInjector, generateCode } from '../../../toolympus/components/PowerDoc/plugins/common';
import { EditorPlugin } from '../../../toolympus/components/PowerDoc';
import { QnABlockElement, QnABlockElementType } from './QnABlockElement';
import { CustomElement } from '../../../slate';

const withQnA = createWithVoidInjector(QnABlockElementType);

export const QnAPlugin: EditorPlugin = {
    key: "qna-block",
    commands: [{
        name: "insert-qna-block",
        menu: { section: "insert-item", label: "Q&A", icon: <LiveHelpOutlined /> },
        invoke: editor => {
            const firstFieldCode = generateCode();
            editor.insertNode({
                type: QnABlockElementType,
                questions: [{ id: firstFieldCode }],
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [QnABlockElementType]: QnABlockElement },
    inject: withQnA,
};
