import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { Dialog, LoadingIndicator } from '../../toolympus/components/primitives';
import { useFormats } from '../../toolympus/components/schemed';
import { useTextFilter } from '../../toolympus/components/schemed/Filtering/useTextFilter';
import { utc } from '../../toolympus/components/timezone';
import { Link } from 'react-router-dom';
import { Close } from '@mui/icons-material';

export interface Translation {
    _id: number | string;
    title: string;
    slug: string;
    lang: string;
    time?: string;
}

export interface TranslationRecordsData {
    translations: Translation[];
    loadAvailable: () => void;
    isLoading: boolean;
    available: Translation[];
    addTranslation: (t: Translation) => Promise<void>;
    unlinkTranslation: (t: Translation) => Promise<void>;
}

interface TranslationRecordsProps extends TranslationRecordsData {
    getItemLink: (t: Translation) => string;
}

const TranslationsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 1rem;
    row-gap: 0.25rem;
    align-items: center;

    & .page {
        display: flex;
        flex-flow: column;
        text-decoration: none;
        color: unset;
        line-height: 1;
    }
`;

export const TranslationRecords = (props: TranslationRecordsProps) => {
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const filter = useTextFilter<Translation>(t => `${t.title} ${t.slug}`);
    
    const startAdding = () => {
        setIsAdding(true);
        props.loadAvailable();
    }

    const { formatDatetime } = useFormats();

    const hasSomeTranslations = props.translations && props.translations.length > 0;

    return (
        <>
            {!hasSomeTranslations && <Button size="small" color="primary" onClick={startAdding}>link translation</Button>}
            
            {hasSomeTranslations &&
                <TranslationsWrapper>
                    {props.translations.map(t => (
                        <React.Fragment key={t._id}>
                            <Link to={props.getItemLink(t)} className="page">
                                <Typography>({t.lang}) {t.title}</Typography>
                                <Typography variant="caption" color="textSecondary">{t.slug}</Typography>
                            </Link>
                            <div>
                                <IconButton size="small" onClick={() => props.unlinkTranslation(t)}>
                                    <Close />
                                </IconButton>
                            </div>
                        </React.Fragment>
                    ))}
                </TranslationsWrapper>}

            <Dialog
                isOpen={isAdding}
                close={() => setIsAdding(false)}
                dialogTitle="Add translation"
                noFullscreen
                >
                <TextField
                    value={filter.filter}
                    onChange={e => filter.setFilter(e.target.value)}
                    fullWidth
                    autoFocus
                    label="Search"
                    />

                {props.isLoading && <LoadingIndicator />}
                {!props.isLoading && <List>
                    {filter.filterData(props.available).map(t => (
                        <ListItem key={t._id} button onClick={() => { props.addTranslation(t); setIsAdding(false); }}>
                            <ListItemText
                                primary={t.title}
                                secondary={`${t.slug} ${t.time ? formatDatetime(utc.toLocal(t.time)) : ""}`}
                                />
                        </ListItem>
                    ))}
                </List>}
            </Dialog>
        </>
    );
}
