import React from 'react';
import { ConfigurableFormsEditorPlugin, ConfigurableFormsSelectionProvider } from '../toolympus/components/PowerDoc/plugins/ConfigurableForms';
import { PowerEditorBaseProps, PowerEditorBase } from '../toolympus/components/PowerDoc';
import { ColumnsPlugin } from '../toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from '../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../toolympus/components/PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from '../toolympus/components/PowerDoc/plugins/MediaFiles';
import { PanelPlugin } from '../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from '../toolympus/components/PowerDoc/plugins/Script';
import { TablesPlugin } from '../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../toolympus/components/PowerDoc/plugins/Tiles';
import { PowerEditorSettingsProvider } from '../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { QnAPlugin } from './Common/QnAEditorPlugin';
import { PeopleProvider, usePeoplePlugin } from './People/PeopleEditorPlugin';


export const YimaEditorUnwrapped = (props: PowerEditorBaseProps) => {
    const mediaFilesPlugin = useMediaFilesPlugin();
    const peoplePlugin = usePeoplePlugin();

    return (
        <PowerEditorBase
            {...props}
            plugins={[
                mediaFilesPlugin,
                CustomFieldsBlockPlugin,
                TilesPlugin,
                ColumnsPlugin,
                PanelPlugin,
                ScriptPlugin,
                ExpanderPlugin,
                TablesPlugin,
                peoplePlugin,
                QnAPlugin,
                ConfigurableFormsEditorPlugin,
            ]}
            />
    );
}

export const YimaEditor = (props: PowerEditorBaseProps) => (
    <PowerEditorSettingsProvider
        EditorComponent={YimaEditorUnwrapped}
        suggestedBlockSettingValues={{
            table: {
                _class: ["no_border", "results", "center-link"]
            },
            "expander-block": {
                _class: ["gutter-small"]
            },
            tiles_block: {
                _class: ["alt-background", "plain", "vertical", "organizations"]
            },
            any: {
                _class: ["no_border", "results", "center-link"],
            }
        }}
        suggestedBlockSettings={[
            "_class",
            "open",
            "link_label",
            "noArrows",
            "no_carousel",
        ]}>
        <PeopleProvider>
            <ConfigurableFormsSelectionProvider apiPath="/api/form/config" formsPagesPath="/form">
                <YimaEditorUnwrapped {...props} />
            </ConfigurableFormsSelectionProvider>
        </PeopleProvider>
    </PowerEditorSettingsProvider>
);