import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useTranslationRecords } from "../Common/useTranslationRecords";
import { Event, EventsApiPath } from "./useEventsList"


export const useEditEvent = (id: string | number) => {
    const data = useCrudItem<Event>(`${EventsApiPath}/${id}`, {
        defaultValue: {} as Event,
        returnPath: "/event",
    });

    const publish = () => {
        if(!data.data.published_datetime) {
            const saver = data.hasChanges ? data.save : () => Promise.resolve({});

            saver().then(() => {
                apiFetch<Event>(
                    `${EventsApiPath}/${data.data._id}/publish`,
                    "post",
                    {})
                    .then(n => data.setData(n));
            });
        }
    }

    const copyItem = () => {
        const copy = { ...data.data };
        copy.title = `${copy.title} (copy)`;
        copy.slug = `${copy.slug}-copy`;

        delete (copy as any)._id;
        delete (copy as any).localization_id;
        delete (copy as any).translations;
        delete copy.published_by;
        delete copy.published_datetime;

        return apiFetch<Event>(
            `${EventsApiPath}`,
            "post",
            copy);
    }

    const { schema } = useSingleSchema(`${EventsApiPath}/uiconfig`);

    const translations = useTranslationRecords(data.data, {
        apiPath: EventsApiPath,
        view: "all",
        reload: data.reload,
        transform: e => ({ ...e, time: e.event_datetime }),
    });

    return {
        ...data,
        schema,
        publish,
        copyItem,
        translations,
    }
}
