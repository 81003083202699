import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useDictionaries } from "../../toolympus/hooks/useDictionaries";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";

export interface Committee {
    _id: string;
    title: string;
    slug: string;
    sortorder: number;
    about?: any;
    mission?: any;
    content?: any;
    logo_url?: string | null;
    published_datetime?: string | null;
    translations?: any;
}

export interface CreateCommittee extends Pick<Committee, "title" | "slug" | "sortorder"> {

}

export const CommitteeApiPath = "/api/committee";

export const useCommitteeList = () => {
    const data = useCrudUnpagedList<Committee>(CommitteeApiPath, {
        defaultView: "all",
        filterBy: n => `${n.title} ${n._id}`,
    });

    const { schema } = useSingleSchema(`${CommitteeApiPath}/uiconfig`);

    const dictionaries = useDictionaries();

    const newItem = useNewItem<CreateCommittee, Committee>(CommitteeApiPath, {
        title: "",
        slug: "",
        sortorder: 0,
    }, {

    });

    return {
        ...data,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as Committee);
        },
        newItem: {
            ...newItem,
            save: () => {
                return newItem.save().then(x => {
                    dictionaries.reload();
                    return x;
                })
            }
        },
        schema,
    }
}
