import React from 'react';
import { SignupForm } from '../../toolympus/components/auth';
import { SignupMode, useSignup } from '../../toolympus/hooks/auth';
import { PublicPageWrapper } from '../Common/PublicPageWrapper';

export const SignupContainer = () => {
    const mode = SignupMode.Invite;
    const signupData = useSignup({ signupApiPath: "/api/signup-invite", mode: mode, loginOnSuccess: true, redirectPath: "/" });

    return (
        <PublicPageWrapper>
            <SignupForm
                signupData={signupData}
                mode={mode}
                />
        </PublicPageWrapper>
    );
}