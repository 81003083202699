import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { CallMade, DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { Form, FormGrid, OccupyFreeSpace, SaveButton, SlugEditControl } from '../../toolympus/components/primitives';
import { YimaEditor } from '../YimaEditor';
import { useProjectEdit } from './useProjectEdit';
import { MediaLibPicker, useMediaLibContext } from '../../toolympus/components/medialib';
import { DefaultLocaleSwitch } from '../../toolympus/components/LocalizedRecords';
import { Project } from './useProjectList';
import { GoToSite } from '../Common/GoToSite';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { usePublishOrPublished } from '../Common/PublishOrPublishedDate';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';

export const ProjectPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const data = useProjectEdit(id);
    useSaveable(data);
    const { controls } = useItemWithControls(data, { schema: data.schema });
    const medialib = useMediaLibContext();

    const PublishedControl = usePublishOrPublished(v => data.save({ published_datetime: v }), [data.data.published_datetime]);

    const remove = useActionWithConfirmation(() => data.remove(), { title: "Delete project", confirmationLabel: "Delete" });

    return (
        <Form
            title="Project"
            headerItems={<>
                <OccupyFreeSpace />
                <GoToSite entity="project" id={data.data.slug} lang={data.locale} />
                <DefaultLocaleSwitch locale={data.locale} setLocale={data.setLocale} />
                <IconButton size="small" onClick={() => data.copyItem().then(n => history.push(`/project/${n._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                <IconButton size="small" color="secondary" onClick={() => remove.run()}>
                    <DeleteOutlined />
                </IconButton>
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            <FormGrid columns="2fr 1fr">
                {controls([
                    ["title"],
                    ["slug", { control: SlugEditControl, slugSource: data.data?.title || "" }],
                    ["description_brief", { autoRows: true, wrapperStyle: { gridColumn: "span 2" } }],
                  ])}
            </FormGrid>

            <FormGrid alignItems="start">
                {controls([
                    ["committee", { 
                        actions: (row: Project) => row.committee ? <Link to={`/committee/${row.committee.substring("committee_".length)}`}><IconButton size="small"><CallMade /></IconButton></Link> : undefined,
                    }],
                    ["published_datetime", { utcToLocal: true, control: PublishedControl }],
                    ])}

                <MediaLibPicker
                    selectFile={mf => data.update({ photo_url: mf ? medialib.getFilepath(mf) : null })}
                    selectedUrl={data.data.photo_url}
                    closeOnSelect
                    label="Photo"
                    />                    
            </FormGrid>

            <Typography variant="caption" color="textSecondary" style={{ marginTop: "1rem" }}>Description</Typography>
            <YimaEditor
                key={`about_${data.locale}_${data.data._id}`}
                content={data.data.description}
                update={v => data.update({ description: v })}
                />

        </Form>
    );
}
