import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#00A6E4',
        },
        secondary: {
            main: '#D84547',
        },
    },
    typography: {
        fontFamily: "Helvetica",
    },
    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
