import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useLocalizedRecord } from "../../toolympus/components/LocalizedRecords";
import { CustomFieldsBlockElementType } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { News } from "../News/useNewsList";
import { Project } from "../Projects/useProjectList";
import { Committee, CommitteeApiPath } from "./useCommitteeList"
import { useCommitteePeople } from "./useCommitteePeople";

export const createEmptyCustomBlockComponent = (customType: string) => ({
    type: CustomFieldsBlockElementType,
    fields: { a: { label: "block_type", value: customType }},
    fieldsCodes: ["a"],
    children: [{ text: "" }],
  });

const DefaultContent = {
    blocks: [
        createEmptyCustomBlockComponent("committee_about"),
        createEmptyCustomBlockComponent("committee_chairs"),
        createEmptyCustomBlockComponent("committee_projects"),
        createEmptyCustomBlockComponent("committee_news"),
        createEmptyCustomBlockComponent("committee_members"),
        createEmptyCustomBlockComponent("committee_join"),
    ]
}

export const useCommitteeEdit = (id: string | number) => {
    const data = useCrudItem<Committee>(`${CommitteeApiPath}/${id}`, {
        defaultValue: {} as Committee,
        returnPath: "/committee",
    });

    const copyItem = () => {
        return apiFetch<Committee>(
            `${CommitteeApiPath}`,
            "post",
            {
                title: `${data.data.title} (copy)`,
                slug: `${data.data.slug}-copy`,
                sortorder: data.data.sortorder + 1,
                about: data.data.about,
                mission: data.data.mission,
                logo_url: data.data.logo_url,
                translations: data.data.translations,
            });
    }

    const { schema } = useSingleSchema(`${CommitteeApiPath}/uiconfig`);

    const localized = useLocalizedRecord(data.data, {
        defaultLocale: "ru",
        translatedFields: [
            "title",
            "about",
            "mission",
            "content",
        ],
        translationsField: "translations",
        updateItem: (o,c) => data.update(c),
    });

    const { locale, setLocale } = localized;

    const projects = useLoadedData<Project[]>(`/api/project/committee/committee_${id}?lang=${locale}&include-unpublished=true`, []);
    const news = useLoadedData<News[]>(`/api/news/category/committee_${id}?lang=${locale}&include-unpublished=true`, []);

    const people = useCommitteePeople(id);

    return {
        ...data,
        data: localized.item,
        update: (c: Partial<Committee>) => localized.updateItem(localized.item, c),
        clearContent: () => localized.updateItem(localized.item, { content: null }),
        setDefaultContent: () => localized.updateItem(localized.item, { content: DefaultContent }),
        schema,
        copyItem,
        locale,
        setLocale,
        projects,
        news,
        people,

        save: (extraChanges?: Partial<Committee>) => {
            if(data.hasChanges || !!extraChanges) {
                data.save(extraChanges);
            }
            if(people.hasChanges) {
                people.save();
            }
        },
        hasChanges: data.hasChanges || people.hasChanges,
    }
}
