import React, { useState } from 'react';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
// import { SchemaProvider } from './toolympus/hooks/useSchema';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import styled from '@emotion/styled';
import { MediaLibProvider } from './toolympus/components/medialib';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';


const SidebarDrawer = styled(DrawerPaperView)`
    & .MuiDrawer-paper {
        background-color: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
    }
    
    & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.text.secondary};
    }
`;

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Log out' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
            <MediaLibProvider apiPath="/api/media" spaceId="1">
                <DictionariesProvider apiPath="/api/dictionary">
                    <AppWrapper>
                        <Sidebar
                            top={<Logo />}
                            menu={<Menu menuItems={menuItems} />}
                            bottom={bottom}
                            open={true}
                            onClose={() => { }}
                            drawerPaperProps={{ shadow: true }}
                            drawerPaperComponent={SidebarDrawer}
                        />
                        <AppContentSimple fit100Height>
                            <Routes routes={routes} notFoundRedirect="/news" />
                        </AppContentSimple>
                    </AppWrapper>
                </DictionariesProvider>
            </MediaLibProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

