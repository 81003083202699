import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";

export interface Person {
    _id: number;
    name: string;
    description_brief?: string;
    description?: any;
    photo_url?: string | null;
    translations?: any;
}

export interface CreatePerson extends Pick<Person, "name"> {

}

export const PersonApiPath = "/api/person";

export const usePersonList = () => {
    const data = useCrudUnpagedList<Person>(PersonApiPath, {
        defaultView: "all",
        filterBy: n => `${n.name} ${n._id}`,
    });

    const { schema } = useSingleSchema(`${PersonApiPath}/uiconfig`);

    const newItem = useNewItem<CreatePerson, Person>(PersonApiPath, {
        name: "",
    });

    return {
        ...data,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as Person);
        },
        newItem,
        schema,
    }
}
