import React from "react";
import { Settings, PhotoAlbumOutlined, ReceiptOutlined, DescriptionOutlined, EmojiPeopleOutlined, ListAlt, SupervisedUserCircleOutlined, PeopleOutlined, HelpOutline, AssignmentTurnedInOutlined, TodayOutlined } from "@mui/icons-material";
import { Roles } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { MediaLibContainer } from "./toolympus/components/medialib/MediaLibForm";
import { NewsList, NewsPage } from "./components/News";
import { PageList, PagePage } from "./components/Pages";
import { SubscribersList } from "./components/Subscribers";
import { SettingsPage } from "./components/SettingsPage";
import { CommitteeList, CommitteePage } from "./components/Committee";
import { ProjectList, ProjectPage } from "./components/Projects";
import { PersonList, PersonPage } from "./components/People";
import { DocumentationRoutes } from "./toolympus/components/Documentation";
import { ConfigurableFormsRoutes } from "./toolympus/components/ConfigurableForms";
import { Locales } from "./toolympus/components/LocalizedRecords";
import { EventsList, EventPage } from "./components/Events";

const LocalizationSettings = { defaultLocale: "ru", locales: Locales };

const InnerRoutes: MenuRoute[] = [
    {
        path: "/news/:id",
        component: () => <NewsPage />,
        hidden: true,
    },
    {
        path: "/news",
        title: "News",
        icon: <ReceiptOutlined />,
        component: () => <NewsList />,
    },
    {
        path: "/event/:id",
        component: EventPage,
        hidden: true,
    },
    {
        path: "/event",
        title: "Events",
        icon: <TodayOutlined />,
        component: EventsList,
    },
    {
        path: "/page/:id",
        component: () => <PagePage />,
        hidden: true,
    },
    {
        path: "/page",
        title: "Pages",
        icon: <DescriptionOutlined />,
        component: () => <PageList />,
        alsoActivateForPath: p => p.startsWith("/page/"),
    },

    

    
    {
        path: "/committee/:id",
        component: () => <CommitteePage />,
        hidden: true,
    },
    {
        path: "/committee",
        title: "Committees",
        icon: <SupervisedUserCircleOutlined />,
        component: () => <CommitteeList />,
        alsoActivateForPath: p => p.startsWith("/committee/"),
    },
    {
        path: "/project/:id",
        component: () => <ProjectPage />,
        hidden: true,
    },
    {
        path: "/project",
        title: "Projects",
        icon: <ListAlt />,
        component: () => <ProjectList />,
        alsoActivateForPath: p => p.startsWith("/project/"),
    },
    {
        path: "/person/:id",
        component: () => <PersonPage />,
        hidden: true,
    },
    {
        path: "/person",
        title: "People",
        icon: <PeopleOutlined />,
        component: () => <PersonList />,
        alsoActivateForPath: p => p.startsWith("/person/"),
    },


    {
        path: "/form",
        title: "Forms",
        icon: <AssignmentTurnedInOutlined />,
        component: () => <ConfigurableFormsRoutes apiPath="/api/form/config" localization={LocalizationSettings} />,
        alsoActivateForPath: p => p.startsWith("/form/"),
    },

    
    {
        path: "/media",
        title: "Media",
        icon: <PhotoAlbumOutlined />,
        component: () => <MediaLibContainer apiPath="/api/media" spaceId="1" urlPrefix="/uploads" />,
    },
    {
        path: "/subscribers",
        title: "Subscribers",
        icon: <EmojiPeopleOutlined />,
        component: () => <SubscribersList />,
    },
    { 
        path: '/system',
        title: 'System',
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                users={{
                    userRoles: Roles,
                    allowInvites: true,
                    allowPaswordReset: true,
                    allowPaswordChange: true,
                    twoFactorAuthEnabled: false,
                }}
                messages={{}}
                queryConsole={{}}
                emails={{ apiPath: "/emails"}}
                robud={{}}
                noCloseButton
                extraItems={[
                    {
                        key: "settings",
                        label: "Settings",
                        icon: <Settings />,
                        items: [{
                            component: SettingsPage,
                            key: "settings",
                            label: "Settings",
                        }]
                    },
                    {
                        key: "documentation",
                        label: "Documentation",
                        icon: <HelpOutline />,
                        items: [{
                            component: () => <DocumentationRoutes rootSlug="root" apiPath="/api/documentation" />,
                            key: "documentation",
                            label: "Documentation",
                        }]
                    },
                ]}
            />),
    },
];

export default InnerRoutes;
