import { useEffect, useState } from "react";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { CustomElement } from "../../../slate";
import { useDialogState } from "../../../toolympus/components/primitives";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { CommitteePeopleData } from "../../Committee/useCommitteePeople";
import { Person } from "../usePersonList";
import { PeopleBlockElementType, WithPeople } from "./PeopleBlockElement";
import { usePeople } from "./PeopleContext";

export interface BlockPeopleSelectionData extends CommitteePeopleData {
    open: (editor: Editor, element: WithPeople | null) => void;
}

export const useBlockPeopleSelection = (): BlockPeopleSelectionData => {
    const dialog = useDialogState();
    const [element, setElement] = useState<WithPeople | null>(null);
    const [editor, setEditor] = useState<Editor | null>(null);
    const allPeople = usePeople();
    const filter = useTextFilter<Person>(p => p.name);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    
    useEffect(() => {
        if(!element) {
            setSelectedIds([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    const isSelected = (personId: string | number) => {
        const found = selectedIds.find(id => personId === id);
        if(found) {
            return { _id: found, committee_id: 0 };
        } else {
            return false;
        }
    }

    
    const selectPerson = (personId: number, select: boolean, chair: boolean) => {
        if(select && !chair) {
            setSelectedIds(ids => [...ids, personId]);
        } else {
            setSelectedIds(ids => ids.filter(id => id !== personId));
        }
    }

    const selectedPeople = selectedIds.map(id => allPeople.peopleDict[id]).filter(p => !!p).map(p => ({ ...p, committee_id: 0 }));

    return {
        selectedPeople,
        isLoading: allPeople.isLoading,
        allPeople: filter.filterData(allPeople.people),
        dialog: {
            ...dialog,
            close: () => {
                if(editor) {
                    if(element) {
                        const path = ReactEditor.findPath(editor, element as CustomElement);
                        Transforms.setNodes(
                            editor,
                            { people: selectedIds } as any,
                            { at: path }
                        );
                    } else {
                        if(selectedIds.length) {
                            editor.insertNode({
                                type: PeopleBlockElementType,
                                people: selectedIds,
                                children: [{ text: "", }],
                            } as any);
                        }
                    }
                }
                dialog.close();
                setElement(null);
                setEditor(null);
            },
        },
        filter,
        
        isSelected,
        selectPerson,
        
        hasChanges: false,
        save: () => Promise.resolve(),

        open: (editor, element) => {
            if(editor) {
                setEditor(editor);
                allPeople.ensureLoaded();
                dialog.open();
                
                if(element) {
                    setElement(element);    
                    setSelectedIds(element?.people || []);
                } else {
                    setSelectedIds([]);
                }
            }
        },
    }
}
