import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Logo } from '.';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    & img {
        width: 300px;
    }
`;

export const PublicPageWrapper = (props: { children?: ReactNode }) => {
    return <Wrapper>
        <Logo />
        {props.children}
    </Wrapper>
}
