import React from 'react';
import styled from '@emotion/styled';
import LogoImg from '../../logo.svg';

const LogoTag = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
`;

export const Logo = () => (
    <LogoTag src={LogoImg} alt="YIMA" />
);