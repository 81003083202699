import React from 'react';
import styled from '@emotion/styled';
import { IconButton, TextField, Typography } from '@mui/material';
import { Add, ArrowDownward, ArrowUpward, DeleteOutlined } from '@mui/icons-material';
import { RenderElementProps, useSelected } from 'slate-react';
import isHotkey from 'is-hotkey';
import { BlockSelectionCss, Buttons } from '../../../toolympus/components/PowerDoc/elements/Common';
import { useQnA } from './useQnA';
import { usePowerEditorContext } from '../../../toolympus/components/PowerDoc/slate/PowerEditorContext';
import { usePowerEditorSettings } from '../../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { PowerEditorBase } from '../../../toolympus/components/PowerDoc';
import { FormGrid } from '../../../toolympus/components/primitives';

export const QnABlockElementType = "qna-block";


const Wrapper = styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    ${props => BlockSelectionCss(props)}

    & > div {
        width: 100%;
    }
`;

const Question = styled(FormGrid)`
    width: 100%;
    padding: 0.5rem 0;
    

    
    & > :last-child > * {
        display: block;
    }
`;
Question.defaultProps = { columns: "1fr max-content", forceEvenColumns: true, noMargin: true };

export const QnABlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    const questions = useQnA(element);

    const isSelected = useSelected();
    const { viewMode } = usePowerEditorContext();
    
    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    return (
        <Wrapper {...attributes} isSelected={isSelected}>
            {children}

            <div style={{ width: "100%" }} contentEditable={false}>
                <Typography variant="caption" color="textSecondary">Q&A</Typography>
                {questions.questions.map(tile => (
                    <Question key={tile.id}>
                        <FormGrid columns="1fr" onKeyDown={e => { if(isHotkey("mod+Enter", e)) { questions.insertQuestion() }}} noMargin>
                            <TextField
                                key="title"
                                value={tile.title || ""}
                                onChange={e => questions.updateQuestion(tile.id, { title: e.target.value })}
                                label=""
                                InputProps={{ readOnly: viewMode }}
                                placeholder="Question"
                                autoFocus={tile.id === questions.insertedId}
                                />
                            
                            <EditorComponent
                                content={tile.content}
                                update={v => questions.updateQuestion(tile.id, { content: v })}
                                placeholder="Answer"
                                viewMode={viewMode}
                                />
                        </FormGrid>

                        <div>
                            {questions.questions.length > 0 && <IconButton size="small" onClick={() => questions.moveQuestionUp(tile.id)}><ArrowUpward /></IconButton>}
                            <IconButton size="small" onClick={() => questions.removeQuestion(tile.id)}><DeleteOutlined /></IconButton>
                            {questions.questions.length > 0 && <IconButton size="small" onClick={() => questions.moveQuestionDown(tile.id)}><ArrowDownward /></IconButton>}
                        </div>
                    </Question>
                ))}
            </div>

            <Buttons>
                <IconButton size="small" onClick={() => questions.insertQuestion()}><Add /></IconButton>
            </Buttons>
        </Wrapper>
    );
}
