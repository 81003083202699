import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useLocalizedRecord } from "../../toolympus/components/LocalizedRecords";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { Project, ProjectApiPath } from "./useProjectList"


export const useProjectEdit = (id: string | number) => {
    const data = useCrudItem<Project>(`${ProjectApiPath}/${id}`, {
        defaultValue: {} as Project,
        returnPath: "/project",
    });

    const copyItem = () => {
        return apiFetch<Project>(
            `${ProjectApiPath}`,
            "post",
            {
                title: `${data.data.title} (copy)`,
                description: data.data.description,
                description_brief: data.data.description_brief,
                photo_url: data.data.photo_url,
                translations: data.data.translations,
            });
    }

    const { schema } = useSingleSchema(`${ProjectApiPath}/uiconfig`);

    const localized = useLocalizedRecord(data.data, {
        defaultLocale: "ru",
        translatedFields: [
            "title",
            "description",
            "description_brief",
        ],
        translationsField: "translations",
        updateItem: (o,c) => data.update(c),
    });

    const { locale, setLocale } = localized;

    return {
        ...data,
        data: localized.item,
        update: (c: Partial<Project>) => localized.updateItem(localized.item, c),
        schema,
        copyItem,
        locale,
        setLocale,
    }
}
