import { useEffect, useMemo, useState } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { useDialogState } from "../../toolympus/components/primitives";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { Person, PersonApiPath } from "../People/usePersonList"
import { CommitteeApiPath } from "./useCommitteeList";

export interface CommitteePerson {
    _id: number;
    committee_id: number;
    is_chair?: boolean;
}

export interface CommitteePersonPerson extends Person {
    is_chair?: boolean;
}

export const useCommitteePeople = (committeeId?: string | number) => {
    const dialog = useDialogState();
    const allPeople = useLoadedData<Person[]>(PersonApiPath, [], committeeId !== undefined);
    const apiPath = `${CommitteeApiPath}/${committeeId}/person`;
    const committeePeople = useLoadedData<(Person & CommitteePerson)[]>(apiPath, [], committeeId !== undefined);

    const [committeePeopleSelected, setCommitteePeopleSelected] = useState<CommitteePerson[]>([]);
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    useEffect(() => {
        setCommitteePeopleSelected(committeePeople.data);
    }, [committeePeople.data]);


    const selectedPeople = useMemo(() => (committeePeopleSelected.map(cp => {
        const person = allPeople.data.find(p => p._id === cp._id);
        if(person) {
            return { ...person, ...cp };
        } else {
            return null;
        }
    })
    .filter(cp => !!cp) as (Person & CommitteePerson)[])
    .sort((a,b) => {
        if(a.is_chair && !b.is_chair) {
            return -1;
        } else if(b.is_chair && !a.is_chair) {
            return 1;
        } else {
            return a.name >= b.name ? 1 : -1;
        }
    }),
    [committeePeopleSelected, allPeople.data]);

    const filter = useTextFilter<Person>(p => `${p.name} ${p.translations?.en?.name || ""}`);

    const isSelected = (personId: string | number): false | CommitteePerson => {
        return selectedPeople.find(p => p._id === +personId) || false;
    }

    const selectPerson = (personId: number, select: boolean, isChair: boolean) => {
        const selected = isSelected(personId);
        if(select && !selected) {
            setCommitteePeopleSelected(ppl => [...ppl, { committee_id: +(committeeId || 0), _id: personId, person_id: personId, is_chair: !!isChair }]);
            setHasChanges(true);
        } else if(select && selected) {
            setCommitteePeopleSelected(ppl => ppl.map(p => p._id === personId ? ({ ...p, is_chair: isChair }) : p));
            setHasChanges(true);
        } else if(!select && selected) {
            setCommitteePeopleSelected(ppl => ppl.filter(p => p._id !== personId));
            setHasChanges(true);
        }
    }

    const save = () => {
        return apiFetch<(Person & CommitteePerson)[]>(
            `${CommitteeApiPath}/${committeeId}/person`,
            "put",
            committeePeopleSelected.map(cp => ({ committee_id: +(committeeId || -1), person_id: cp._id, is_chair: !!cp.is_chair })))
            .then(data => {
                setCommitteePeopleSelected(data);
                setHasChanges(false);
            });
    }

    return {
        selectedPeople,
        isLoading: allPeople.isLoading || committeePeople.isLoading,
        allPeople: filter.filterData(allPeople.data),
        dialog,
        filter,

        isSelected,
        selectPerson,

        hasChanges,
        save,
    }
}

export type CommitteePeopleData = ReturnType<typeof useCommitteePeople>;
