import React from "react";
import { CommitteePeopleSelection } from "../../Committee/CommitteePeopleSelection";
import { CommitteePeopleData } from "../../Committee/useCommitteePeople";

interface Props {
    data: CommitteePeopleData;
}

export const PeopleSelectionDialog = (props: Props) => {
    return <CommitteePeopleSelection data={props.data} />;
}
