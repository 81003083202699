import React, { useMemo } from 'react';
import { Button, IconButton } from '@mui/material';
import { FormControlProps } from '../../toolympus/components/schemed/FormControlProps';
import { Close } from '@mui/icons-material';
import { DateTimeEdit } from '../../toolympus/components/schemed/TimeEdit';
import { DateTimeInternalFormat } from '../../toolympus/api/datetimeUtil';
import { utc } from '../../toolympus/components/timezone';

interface Props extends FormControlProps {
    publish: () => void;
}

export const PublishOrPublishedDate = (props: Props) => {
  const { row, field, onChange, publish } = props;
  const value = (row || {})[field];
  return value
      ? <DateTimeEdit
          {...props}
          extraProps={{
              readOnly: true,
              utcToLocal: true,
              ...(props.extraProps || {}),
              control: undefined,
              controlProps: {
                  InputProps: {
                      startAdornment: (
                          <IconButton size="small" color="secondary" onClick={() => onChange(row, { [field]: null })}>
                              <Close />
                          </IconButton>) }},
          }}
          />
      : (
          <Button size="small" color="primary" fullWidth onClick={() => publish()}>
              publish
          </Button>
      );
}


export const usePublishOrPublished = (update: (v: string | null | undefined) => void, memoizer: any[]) => {
  const PublishDate = useMemo(() => {
    return (props: FormControlProps) => {
      return <PublishOrPublishedDate publish={() => update(utc.toServer(new Date()).format(DateTimeInternalFormat))} {...props} />
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memoizer);

  return PublishDate;
}
