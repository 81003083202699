import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useTranslationRecords } from "../Common/useTranslationRecords";
import { News, NewsApiPath } from "./useNewsList"


export const useNewsItem = (id: string | number) => {
    const data = useCrudItem<News>(`${NewsApiPath}/${id}`, {
        defaultValue: {} as News,
        returnPath: "/news",
    });

    const publish = () => {
        if(!data.data.published_datetime) {
            const saver = data.hasChanges ? data.save : () => Promise.resolve({});

            saver().then(() => {
                apiFetch<News>(
                    `${NewsApiPath}/${data.data._id}/publish`,
                    "post",
                    {})
                    .then(n => data.setData(n));
            });
        }
    }

    const copyItem = () => {
        return apiFetch<News>(
            `${NewsApiPath}`,
            "post",
            {
                title: `${data.data.title} (copy)`,
                slug: `${data.data.slug}-copy`,
                content: data.data.content,
                lang: data.data.lang,
                news_datetime: data.data.news_datetime,
                display_datetime: data.data.display_datetime,
            });
    }

    const { schema } = useSingleSchema(`${NewsApiPath}/uiconfig`);

    const translations = useTranslationRecords(data.data, {
        apiPath: NewsApiPath,
        view: "all",
        reload: data.reload,
        transform: n => ({ ...n, time: n.news_datetime }),
    });

    return {
        ...data,
        schema,
        publish,
        copyItem,
        translations,
    }
}
