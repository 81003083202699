import React, { useMemo } from 'react';
import { PeopleOutlined } from '@mui/icons-material';
import { RenderElementProps } from 'slate-react';
import { EditorPlugin } from '../../../toolympus/components/PowerDoc';
import { createWithVoidInjector } from '../../../toolympus/components/PowerDoc/plugins/common';
import { PeopleBlockElement, PeopleBlockElementType } from './PeopleBlockElement';
import { PeopleSelectionDialog } from './PeopleSelectionDialog';
import { useBlockPeopleSelection } from './useBlockPeopleSelection';

const withPeopleBlock = createWithVoidInjector(PeopleBlockElementType);

export const usePeoplePlugin = (): EditorPlugin => {
    const peopleSelection = useBlockPeopleSelection();

    const PeopleBlockElementMemo = useMemo(() => {
        return (props: RenderElementProps) => (
            <PeopleBlockElement openEdit={(editor,element) => peopleSelection.open(editor, element)} {...props} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        key: "people",
        commands: [{
            name: "insert-people",
            invoke: editor => peopleSelection.open(editor, null),
            menu: {
                section: "insert-item",
                icon: <PeopleOutlined />,
                label: "People",
            },
        }],
        inject: withPeopleBlock,
        customBlocks: { [PeopleBlockElementType]: PeopleBlockElementMemo },
        dialogs: <PeopleSelectionDialog data={peopleSelection} />,
    };
}
